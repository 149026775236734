import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Pagina niet gevonden" />
    <div className="not-found">
      <h2 className="text-center py-4">404 Pagina niet gevonden</h2>
      <p className="text-center">Helaas deze pagina bestaat niet.</p>
    </div>
  </Layout>
)

export default NotFoundPage
